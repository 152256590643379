import { setupWorker, graphql, SetupWorkerApi } from 'msw'
import { handlers } from './handlers'

export const worker = setupWorker(...handlers)

declare global {
  interface Window {
    msw: { worker: SetupWorkerApi; graphql: typeof graphql }
  }
}

// Make the `worker` and `graphql` references available globally, so it can be accessed both at runtime and in test suites
global.window.msw = {
  worker,
  graphql,
}
