import { lazy, Suspense } from 'react'
import { AppRegistry } from 'react-native'

// Apply msw mocks defined in mocks/handlers at runtime in the browser for development
if (process.env.REACT_APP_MOCK_API === 'true') {
  require('./mocks')
}

// Check if browser is IE - https://stackoverflow.com/a/22551342/8406949
const checkBrowserIsIE = () => {
  const ua = window.navigator.userAgent
  const old_ie = ua.indexOf('MSIE ')
  const new_ie = ua.indexOf('Trident/')

  if (old_ie > -1 || new_ie > -1) {
    return true
  }

  return false
}

function RootComponent(): JSX.Element {
  const browserIsIE = checkBrowserIsIE()
  if (browserIsIE) {
    return (
      <div>
        <p>
          Hello, we notice you are visiting Beryl on a browser that is no longer
          supported, please reload this page on another browser to continue.
          Thank you.
        </p>
      </div>
    )
  }

  const Root = lazy(() => import('./Root'))

  return (
    <Suspense fallback={<div></div>}>
      <Root />
    </Suspense>
  )
}

AppRegistry.registerComponent('Root', () => RootComponent)
AppRegistry.runApplication('Root', {
  rootTag: document.getElementById('root'),
})
